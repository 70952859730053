<template>
<!-- Sezione hero-->
  <main class="lg:relative">
      <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 class="text-4xl tracking-tight font-extrabold text-coolGray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span class="block xl:inline">Automazione, Domotica, Networking e Assistenza IT</span>
            {{ ' ' }}
            <span class="block text-cyan-400 xl:inline">al vostro servizio!</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-lg text-coolGray-700 sm:text-xl md:mt-5 md:max-w-3xl">
            Progettazione, realizzazione e assistenza di sistemi tecnologici a tutto tondo utilizzando le migliori e più innovative tecnologie.
          </p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <router-link to="/contact" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-coolGray-900 hover:bg-coolGray-700 md:py-4 md:text-lg md:px-10">
                Contattaci
              </router-link>
            </div>            
          </div>
        </div>
      </div>
      <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img class="absolute inset-0 w-full h-full object-cover" src="@/assets/img/copertina.jpg" alt="Copertina" />
      </div>  
    </main>

<!-- Aggiunta la sezione per staccare nei colori-->
<div class="relative pt-16 pb-16 overflow-hidden">
    <div class="bg-coolGray-900">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-6 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-20">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div class="py-8 lg:py-16">                  
            <h1 class="mt-4 text-4xl pb-4 tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              <span class="text-white">Servizi volti a soddisfare le esigenze del cliente con</span> <span class="text-cyan-400"> qualità, puntualità e trasparenza.</span>
            </h1>
            <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">I nostri servizi</h1>
            <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Progettazione e relizzazione di:</p>
            <ul class="list-disc list-inside text-l lg:text-xl text-white py-2">
              <li>Impianti di automazione</li>
              <li>Impianti di domotica e videosorveglianza</li>
              <li>Reti cablate e wireless</li>
            </ul> 
            <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Sviluppo di software PLC e/o applicativi in c#, python o js</p>
            <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Sviluppo di applicativi custom per l'interfacciamento di impianti nell'ottica dell'incentivo Industria 4.0</p>                 
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 sm:pr-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/homepageIllustration.svg" alt="Illustrazione homepage"/>
          </div>
        </div>
      </div>
    </div>
</div>


<!--sezione di spiegazione delle varie attività-->
<div class="relative bg-white pt-12 pb-12 overflow-hidden">
    <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-16 w-16  flex items-center justify-start">
                <img class="h-16 w-16 rounded-md bg-coolGray-900" src="@/assets/img/icons/automazione.png" alt="Icona automazione"/>
                <!--<InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />-->
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-coolGray-900">
                Automazione e robotica
              </h2>
              <p class="mt-4 text-lg text-coolGray-700">
                Uilizziamo i migliori brand e le migliori tecnologie per creare sistemi di automazione su misura partendo dalla progettazione fino alla messa in servizio.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/automazione.jpg" alt="Automazione"/>
          </div>
        </div>
      </div>
    </div>
    <div class="py-12">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span class="h-16 w-16  flex items-center justify-start">
                <img class="h-16 w-16 rounded-md bg-coolGray-900" src="@/assets/img/icons/domotica.png" alt="Icona domotica"/>
                <!--<InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />-->
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-coolGray-900">
                Domotica e videosorveglianza
              </h2>
              <p class="mt-4 text-lg text-coolGray-700">
                Ascoltando e collaborando con i nostri clienti creiamo un sistema domotico in grado di farli sentire a casa.<br/>
                Installiamo inoltre sistemi di videosorveglianza affidabili e di qualità.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/domotica.jpg" alt="Domotica"/>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-16 w-16  flex items-center justify-start">
                <img class="h-16 w-16 rounded-md bg-coolGray-900" src="@/assets/img/icons/networking.png" alt="Icona networking"/>
                <!--<InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />-->
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-coolGray-900">
                Networking
              </h2>
              <p class="mt-4 text-lg text-coolGray-700">
                Realizziamo presso i nostri clienti soluzioni di networking complete. Reti cablate e wifi, reti P2P. Forniamo anche servizi di installazione e configurazione VPN 
                per facilitare lo smartworking.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/networking.jpg" alt="Automazione"/>
          </div>
        </div>
      </div>
    </div>
    <div class="py-12">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span class="h-16 w-16  flex items-center justify-start">
                <img class="h-16 w-16 rounded-md bg-coolGray-900" src="@/assets/img/icons/assistenzaIT.png" alt="Icona assistenza IT"/>
                <!--<InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />-->
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-coolGray-900">
                Assistenza IT
              </h2>
              <p class="mt-4 text-lg text-coolGray-700">
                Forniamo un servizio di assistenza e consulenza IT dedicato alle aziende di qualsiasi dimensione, sia per quanto riguarda problematiche riscontrate, sia per quanto riguarda la realizzazione
                di nuovi progetti.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/assistenzaIT.jpg" alt="Domotica"/>
          </div>
        </div>
      </div>
    </div>
        <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-16 w-16  flex items-center justify-start">
                <img class="h-16 w-16 rounded-md bg-coolGray-900" src="@/assets/img/icons/manutenzione.png" alt="Icona manutenzione"/>
                <!--<InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />-->
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-coolGray-900">
                Assistenza e manutenzione
              </h2>
              <p class="mt-4 text-lg text-coolGray-700">
                Diamo grandissima importanza all'assistenza di tutti i nostri prodotti e impianti. Forniamo inoltre un servizio di assistenza e manutenzione su impianti automatizzati per tutte le aziende che 
                necessitano di supporto tecnico specializzato soprattutto nell'ambito dell'automazione industriale e delle problematiche legate alla parte IT e di Networking.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/img/manutenzione.jpg" alt="Automazione"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Sezione dei testimonials - Commentata perché non ci siano problemi>

<section class="bg-coolGray-900">
    <div class="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
      <div class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">
        <div class="md:flex-shrink-0">
          <img class="h-12" src="@/assets/img/logo_greenholiday.png" alt="Green Holiday Cav"/>
        </div>
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-cyan-400 md:flex-grow">
            <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p class="relative text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              </div>
              <div class="ml-4">
                <div class="text-base font-medium text-white">Stefania Rondenda</div>
                <div class="text-base font-medium text-cyan-200">CEO, Green Holiday Cav</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div class="py-12 px-4 border-t-2 border-indigo-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
        <div class="md:flex-shrink-0">
          <img class="h-12" src="@/assets/img/logo_salvibaroni.png" alt="Salvibaroni srl" />
        </div>
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-cyan-400 md:flex-grow">
            <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p class="relative text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis. Nemo expedita voluptas culpa sapiente alias molestiae.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              </div>
              <div class="ml-4">
                <div class="text-base font-medium text-white">Lorenzo Baroni</div>
                <div class="text-base font-medium text-cyan-200">Co-founder, Salvibaroni srl</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>-->

</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>
