<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-coolGray-900 overflow-hidden">
    <div class="relative py-6 lg:py-8">
      <Popover>
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <nav class="relative flex items-center justify-between sm:h-10 md:justify-end" aria-label="Global">
            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <router-link to="/">
                  <span class="sr-only">Logo</span>
                  <img class="h-10 w-auto lg:h-20" src="@/assets/logo_rettangolare.svg" alt="TechNic" />
                </router-link>
                <div class="-mr-2 flex items-center md:hidden">
                  <PopoverButton class="bg-coolGray-900 rounded-md p-2 inline-flex items-center justify-center text-cyan-400 hover:text-cyan-600 hover:bg-coolGray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-400">
                    <span class="sr-only">Open main menu</span>
                    <MenuIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
            </div>
            <div class="hidden md:flex md:space-x-10">
              <router-link v-for="item in navigation" :key="item.name" :to="item.href" class="text-left font-medium text-white hover:text-cyan-400">{{ item.name }}</router-link>
            </div>
          </nav>
        </div>

        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="fixed z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-md bg-coolGray-900 ring ring-cyan-400 ring-opacity-100 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                    <PopoverButton>
                        <router-link to="/">
                            <img class="h-10 w-auto" src="@/assets/logo_rettangolare.svg" alt="TechNic"/>
                        </router-link>
                    </PopoverButton>
                </div>
                <div class="-mr-2">
                  <PopoverButton class="bg-coolGray-900 rounded-md p-2 inline-flex items-center justify-center text-cyan-400 hover:text-cyan-600 hover:bg-coolGray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-400">
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3">
                  <PopoverButton>
                    <router-link v-for="item in navigation" :key="item.name" :to="item.href" class="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-cyan-400">{{ item.name }}</router-link>
                </PopoverButton>
              </div>              
            </div>
          </PopoverPanel>
        </transition>
      </Popover>

      
    </div>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About Us', href: '/about' },
  { name: 'Contatti', href: '/contact' },
]

export default {
  name:'Nav',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>