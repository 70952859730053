<template>
  <div id="nav">
    <Nav/>
  </div>
  <router-view/>
  
  <Footer/>
</template>

<script>

import Footer from '@/components/Footer.vue'
import Nav from '@/components/Navigation.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Nav
  },
  setup() {
   
  },
}
</script>
