import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import VueGtag from 'vue-gtag-next'

createApp(App).use(VueGtag, {
    property: {id: "G-WCYG4WZS97"},
    params:{
        linker: {
            domain: ['tech-nic.it']
        }
    }
}).use(router).mount('#app')
